import { KioskType } from "../types/kiosk";
import { UnreachableCaseError } from "./UnreachableCaseError";

export const getKioskType = (kioskType: KioskType) => {
  switch (kioskType) {
    case KioskType.EVENT:
      return KioskType.EVENT;
    case KioskType.PHYSICAL:
      return KioskType.PHYSICAL;
    case KioskType.VIRTUAL:
      return KioskType.VIRTUAL;
    default:
      throw new UnreachableCaseError(kioskType);
  }
};
