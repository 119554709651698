/* eslint-disable no-unused-vars */
export enum KioskType {
  EVENT = "event",
  VIRTUAL = "virtual",
  PHYSICAL = "physical",
}

export type Kiosk = {
  readonly isVirtualKiosk: boolean;
  readonly name: string;
  readonly sigmaOrgId: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly UUID: string;
  readonly active: boolean;
  readonly kioskId: number;
  readonly kiosk_events?: Array<KioskEvent>;
  readonly kioskType: KioskType;
};
