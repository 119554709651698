import "./Kiosk.less";
import { Layout, List } from "antd";
import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../theme/Colors";
import { Fonts } from "../../../theme/Fonts";
import { Footer } from "../../UI-Components/Footer";
import { Header } from "../../UI-Components/KioskHeader";
import { KioskType } from "../../../types/kiosk";
import LoadingScreen from "../../UI-Components/LoadingScreen";
import { getKioskEvents } from "../../../constant/api";
import { getKioskType } from "../../../utils";
import history from "../../../history";
import { useDefaultErrorHandler } from "../../../utils/useDefaultErrorHandler";

const styles = StyleSheet.create({
  activityText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.sfCompactDisplay,
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  contentWrapper: {
    minHeight: "84vh",
    overflow: "scroll",
    padding: "0 300px 7%",
  },
  headerParagraph: {
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    lineHeight: "24px",
  },
  headerText: {
    color: Colors.headerText,
    fontFamily: Fonts.roboto,
    fontSize: "34px",
    letterSpacing: "0.25px",
    lineHeight: "51px",
  },
  subText: {
    color: Colors.mediumBlack,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.5px",
    lineHeight: "24px",
  },
});

interface Props
  extends RouteComponentProps<{}, {}, { isVirtualKioskTabActive: boolean; kioskId: number; kioskType: KioskType }> {}

const ViewActivities: React.FC<Props> = ({ location }) => {
  const { Content } = Layout;
  const { push } = history;
  const kioskType = getKioskType(location.state.kioskType);
  const isVirtualKiosk = kioskType === KioskType.VIRTUAL;

  const [selectedKioskEvents, setSelectedKioskEvents] = useState<Array<EventList>>([]);
  const [selectedKioskEventIds, setSelectedKioskEventIds] = useState<Array<number>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { errorHandler } = useDefaultErrorHandler();

  useEffect(() => {
    getAllkioskEvents();
  }, []);

  const getAllkioskEvents = async () => {
    if (location.state !== undefined) {
      try {
        const selectedEventIds: Array<number> = [];
        const response = await getKioskEvents(location.state.kioskId);
        const selectedEvents = response.data.item.filter((event: EventList) => {
          if (event.kiosk_events!.length > 0 && event.kiosk_events![0].active) {
            selectedEventIds.push(event.eventId);

            return true;
          }

          return false;
        });
        setSelectedKioskEventIds(selectedEventIds);
        setSelectedKioskEvents(selectedEvents);
      } catch (error: unknown) {
        errorHandler(error);
      }
    }
    setLoading(false);
  };

  const handleEditButton = () => {
    if (location.state !== undefined) {
      push({
        pathname: "/chooseactivities",
        state: {
          isVirtualKioskTabActive: location.state.isVirtualKioskTabActive,
          kioskId: location.state.kioskId,
          kioskType,
          selectedKioskEventIds,
        },
      });
    }
  };

  return (
    <div className="activities-wrapper">
      <React.Fragment>
        <Header
          headerText="View selected activities"
          handleClose={() =>
            push({
              pathname: "/kiosk",
              state: { isVirtualKioskTabActive: isVirtualKiosk, isWebTabActive: true },
            })
          }
        />
        {loading ? (
          <LoadingScreen key={1} minHeight="calc(100vh - 125px)" />
        ) : (
          <div className="content-wrapper">
            <Content className={css(styles.contentWrapper)}>
              <div className="chooseactivities">
                <div style={{ marginTop: "30px" }}>
                  <h1 className={css(styles.headerText)} data-testid="selectedActivitiesHeaderText-ViewActivities">
                    Selected activities
                  </h1>
                  <p className={css(styles.headerParagraph)}>
                    These are the activities that will be available for check-in
                  </p>
                </div>
                <List
                  header={<div></div>}
                  dataSource={selectedKioskEvents}
                  renderItem={(event: EventList) => (
                    <List.Item key={event.eventId}>
                      <span className={css(styles.activityText)} data-testid={`${event.name}-Activity-ViewActivities`}>
                        {event.name}
                      </span>
                    </List.Item>
                  )}
                />
              </div>
            </Content>
          </div>
        )}
        <Footer
          buttonTitle="Done"
          handleButton={() =>
            push({
              pathname: "/kiosk",
              state: { isVirtualKioskTabActive: isVirtualKiosk, isWebTabActive: true },
            })
          }
          handleEditButton={() => handleEditButton()}
          testId="done"
        />
      </React.Fragment>
    </div>
  );
};

export default withRouter(ViewActivities);
