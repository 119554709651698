import { Button, Col, Modal, Row } from "antd";
import { InputAdornment, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "./../../theme/Fonts";
import { KioskType } from "../../types/kiosk";
import PhonelinkSharpIcon from "@material-ui/icons/PhonelinkSharp";

const styles = StyleSheet.create({
  button: {
    color: Colors.darkBlue,
    float: "right",
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    textTransform: "uppercase",
  },
  inputContainer: {
    fontFamily: Fonts.roboto,
    height: "60px",
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "5px",
    width: "97%",
  },
  paper: {
    backgroundColor: Colors.lightWhite,
  },
  root: {
    backgroundColor: Colors.white,
    fontFamily: Fonts.roboto,
    marginTop: "25px",
    overflowX: "hidden",
    overflowY: "auto",
    width: "472px",
  },
});
interface Props {
  readonly kiosk: { name: string; kioskId: number; kioskType?: KioskType.EVENT | KioskType.VIRTUAL };
  readonly showModal: boolean;
  readonly handleCloseModal: () => void;
  readonly handleSaveName: (params: { name: string; kioskId: number }) => void;
}

export const KioskNameModal: React.FC<Props> = ({
  showModal,
  handleCloseModal,
  kiosk: { name, kioskId, kioskType = KioskType.VIRTUAL },
  handleSaveName,
}) => {
  const [kioskName, setKioskName] = useState<string>(name);
  const [showError, setShowError] = useState<boolean>(false);
  const isEventKiosk = kioskType === KioskType.EVENT;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length > 30) {
      e.preventDefault();
      setShowError(true);
    } else {
      setKioskName(e.target.value);
      setShowError(false);
    }
  };

  const saveButton = () => {
    if (kioskName.trim().length > 0) {
      const params = { kioskId, name: kioskName.trim() };
      handleSaveName(params);
    }
  };

  return (
    <Modal
      title={isEventKiosk ? "Edit event name" : "Edit virtual kiosk name"}
      visible={showModal}
      onCancel={handleCloseModal}
      footer={false}
      wrapClassName="edit_kiosk_name"
    >
      <div className={css(styles.paper)}>
        <div className={css(styles.root)}>
          <Row>
            <Col>
              <TextField
                className={css(styles.inputContainer)}
                id="input-with-icon-textfield"
                label={isEventKiosk ? "Event name" : "Virtual kiosk name"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhonelinkSharpIcon color="action" />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  "data-testid": "editNameInputField-KioskNameModal",
                }}
                value={kioskName}
                onChange={handleChange}
              />
              {showError && <p style={{ color: "red" }}>Maximum 30 characters are allowed</p>}
              <Button
                type="link"
                className={css(styles.button)}
                data-testid="editVirtualKioskNameDoneButton-KioskNameModal"
                onClick={saveButton}
              >
                Done
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};
